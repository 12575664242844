import React, {FC, useEffect, useState} from 'react';
import {Link} from 'gatsby';
import Layout from '../../layout/layout';
import {PageHero} from '../../page-hero';
import Section from '../../section';
import FooterCTA from '../footer-cta';
import {SingleWorkflowPageContext} from '../../../types';
import {Translate} from '../../translations';
import {cleanURL} from '../../../helpers';
import './styles.scss';

const SilgleWorkflow: FC<SingleWorkflowPageContext> = ({pageContext, location}) => {
  //pageContext
  const workflowData = pageContext.result.workflow;
  const prevPath = pageContext.result.prevPath;
  const nextPath = pageContext.result.nextPath;
  const [linkTo, setLinkTo] = useState('/ready-made-workflow-library');

  useEffect(() => {
    location && location.state && location.state.linkTo && setLinkTo(location.state.linkTo);
  }, [location]);

  return (
    <>
      <Layout>
        <PageHero
          title={workflowData.name}
          // subtitle2={workflowData.meta_description || undefined}
          titleSmall
          //noPaddingBottom
          className="small"
          nav={
            <div className="navigation-bar">
              <Link to={prevPath} className={`go-to-prev ${!prevPath ? 'disabled' : ''}`}>
                ← Previous workflow
              </Link>
              <Link to={linkTo} className="back-to-home">
                Ready to use workflow templates
              </Link>
              <Link to={nextPath} className={`go-to-next ${!nextPath ? 'disabled' : ''}`}>
                Next workflow →
              </Link>
            </div>
          }
        />
        <Section>
          {/* <div className="col-12 col-lg-6 d-flex workflow-img-wrap align-items-center">
            <img src={workflowData.image} alt="workflow image" className="workflow_image" />
          </div> */}

          <div className={`col-12 d-flex ${workflowData.meta_description ? 'col-lg-6' : ''}`}>
            <div className="m-auto">
              {workflowData.steps.map((item: any, index: number) => (
                <Link
                  to={`/automation/${cleanURL(item.automation.url)}`}
                  className={`workflow-step-container ${
                    index !== workflowData.steps.length - 1 ? 'show-plus-icon' : ''
                  }`}
                >
                  <div className="workflow-step-left">
                    <div className="icon-container">
                      <img src={item.automation.icon_url} alt="automation.icon_url" />
                    </div>
                  </div>
                  <div className="workflow-step-right">
                    <h5 className="workflow-step-title">{item.automation.name}</h5>
                    <p className="workflow-step-description">{item.automation.short_description}</p>
                  </div>
                  {index !== workflowData.steps.length - 1 && <span className="plus-icon" />}
                </Link>
              ))}
            </div>
          </div>
          {workflowData.meta_description ? (
            <div className="col-12 col-lg-6 my-auto">
              <p className="l_height30">
                {typeof workflowData.meta_description === 'string' ? (
                  <Translate name={workflowData.meta_description} />
                ) : (
                  workflowData.meta_description
                )}
              </p>
            </div>
          ) : <></>}
        </Section>
        <FooterCTA
          getStartedText={'Get started in minutes'}
          getStartedLink={`https://dash.hexomatic.com/workflows/current-workflow-template/${workflowData.id}`}
          img={workflowData.image}
        />
      </Layout>
    </>
  );
};

export default SilgleWorkflow;
